export default {
  ACT_LOGIN  : "auth/login",
  APP_NAME   : "Pineapple academy",
  BASE_URL   : process.env.VUE_APP_BASE_URL,
  API_URL    : process.env.VUE_APP_BASE_URL+'/api/',
  UPLOAD_URL : process.env.VUE_APP_BASE_URL+'/uploadFiles/',
  NODE_ENV   : process.env.VUE_APP_NODE_ENV
};

/**
 * 선언부
 * UPDATE_PHOTO : "/contacts/${no}/photo"
 * 호출부
 * this.$CONF.UPDATE_PHOTO.replace("${no}", 3)
 */
